@keyframes hide-scroll {
    from, to { overflow: hidden; }
}

@if $animations == false {
    :root {
        scroll-behavior: auto !important;
    }

    *, *::before, *::after {
        transition-delay: 0s !important;
        transition-duration: 0s !important;
    }
}
