::-webkit-input-placeholder {
    color: $color__placeholder;
}

:-moz-placeholder { /* Firefox 18- */
    color: $color__placeholder;
    opacity: 1;
}

::-moz-placeholder {  /* Firefox 19+ */
    color: $color__placeholder;
    opacity: 1;
}

:-ms-input-placeholder {
    color: $color__placeholder;
}
