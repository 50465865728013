.content--editor,
.m-p,
body[class*='cke_'],
.pimcore_wysiwyg[contenteditable='true'] {
    @include margins-block-level-elements;
    @include margins-headings;

    blockquote {
        font-weight: $font__weight--bold;
        font-style: italic;
    }
}
