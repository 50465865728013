button {
    border: 1px solid $color__primary;
    border-radius: $border__radius;
    outline: 0;
    transition: color .2s linear, outline .15s ease-in-out;

    &:focus-visible {
        outline: $border__radius solid $color__primary--disabled;
    }

    &.button {
        appearance: none;
    }
}
