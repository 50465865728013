// .container,
// .container-sm,
// .container-md,
// .container-lg,
// .container-xl,
// .container-xxl,
// .container-fluid {
//     @each $breakpoint, $container-padding-size in $container-padding-sizes {
//         @include media-breakpoint-up(#{$breakpoint}) {
//             padding-right: $container-padding-size;
//             padding-left: $container-padding-size;
//         }
//     }
// }

@include container-class-selector {
    @each $breakpoint, $container-padding-size in $container-padding-sizes {
        @include media-breakpoint-up(#{$breakpoint}) {
            padding-right: $container-padding-size;
            padding-left: $container-padding-size;
        }
    }
}

// .container-xxl {
//     &.ms-0:not(.me-0),
//     &.me-0:not(.ms-0) {
//         @media (min-width: #{map-get-last-value($container-max-widths)}) {
//             max-width: calc(100% - (100% - #{map-get-last-value($container-max-widths)}) / 2);
//         }
//     }
// }

@each $breakpoint, $container-max-width in $container-max-widths {
    .container-#{$breakpoint} {
        &.ms-0:not(.me-0),
        &.me-0:not(.ms-0) {
            @media (min-width: #{$container-max-width}) {
                max-width: calc(100% - (100% - #{$container-max-width}) / 2);
            }
        }
    }
}

.col,
[class*="col-"] {
    img {
        height: auto;
    }
}


/**
 * Column gutters
 * Make use of `.g-*`, `.gx-*` or `.gy-*` utilities to change spacing between the columns.
 */

//@each $breakpoint in map-keys($grid-breakpoints) {
//	$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
//
//	@include media-breakpoint-up($breakpoint, $grid-breakpoints) {
//		@each $key, $value in $gutters {
//			.g#{$infix}-#{$key},
//			.gx#{$infix}-#{$key} {
//				margin-right: $value / -2;
//				margin-left: $value / -2;
//
//				> .col,
//				> [class*="col-"] {
//					padding-right: $value / 2;
//					padding-left: $value / 2;
//				}
//			}
//
//			.g#{$infix}-#{$key},
//			.gy#{$infix}-#{$key} {
//			    margin-top: $value * -1;
//
//			    > .col,
//			    > [class*="col-"] {
//			        margin-top: $value;
//			    }
//			}
//		}
//	}
//}
