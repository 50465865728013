@use "sass:math";

//.hidden {
//    display: none !important;
//}

.hyphenate {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
       -moz-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
}

//.backstretch {
//    background-repeat: no-repeat;
//    background-position: center center;
//    background-size: cover;
//}

.responsive {
    position: relative;
    padding-bottom: percentage(math.div(3, 4));
    max-width: 100%;
    height: 0;
    overflow: hidden;

    &--1\:1 {
        padding-bottom: percentage(math.div(1, 1));
    }

    &--16\:9 {
        padding-bottom: percentage(math.div(9, 16));
    }

    &--21\:9 {
        padding-bottom: percentage(math.div(9, 21));
    }

    > *:not(img):not(video) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    video {
        max-width: 100%;
        height: auto;

        &[controls] {
            clip-path: inset(0 1px);
        }
    }

    img {
        position: absolute;
        top: -9999px;
        left: -9999px;
        right: -9999px;
        bottom: -9999px;
        margin: auto;
        width: auto;
        height: 100%;
    }

    &--table {
        width: 100%;
        overflow-y: auto;
        margin: 0 0 1em;

        @include webkit-scrollbar;

        > table {
            margin: 0 !important;
        }
    }
}
