::-moz-selection {
    text-shadow: none;
    color: $color__selection--foreground;
    background: $color__selection--background;
}

::selection {
    text-shadow: none;
    color: $color__selection--foreground;
    background: $color__selection--background;
}

.text-negative {
    ::-moz-selection {
        text-shadow: none;
        color: $color__selection--background;
        background: $color__selection--foreground;
    }

    ::selection {
        text-shadow: none;
        color: $color__selection--background;
        background: $color__selection--foreground;
    }
}
